<template>
  <div class="card">
    <div class="card-header">
      <a-button class="mr-2" @click="showModalNewFUMO" :disabled="!$auth.isLoggedIn()">
        <template #icon><i class="fa fa-plus" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length != 1)||(!$auth.isLoggedIn())"
        @click="editEntity(selectedRows)"
      >
        <template #icon><i class="fa fa-pencil" /></template>
      </a-button>
      <a-button
        class="mr-2"
        :disabled="(selectedRowKeys.length == 0)||(!$auth.isLoggedIn())"
        @click="deleteEntity(selectedRowKeys)"
      >
        <template #icon><i class="fa fa-trash" /></template>
      </a-button>
    </div>

    <a-table
      :dataSource="gridData"
      :columns="gridColumns"
      :scroll="{ x: 'calc(100%)', y: '100%' }"
      :customRow="rowClick"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      rowKey="id"
    />
    
    <a-modal
      width="100"
      v-model:visible="visibleFUMO"
      title="FUMO"
      @ok="handleOkFUMO"
    >
      <a-form ref="FUMOForm" :model="FUMOFormState">
        <a-form-item label="Наименование">
          <a-input v-model:value="FUMOFormState.FUMOTitle" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <a-form-item label="Код">
          <a-input v-model:value="FUMOFormState.code" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <a-form-item label="Председатель">
          <a-input v-model:value="FUMOFormState.chairman" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <a-form-item label="Адрес">
          <a-input v-model:value="FUMOFormState.FUMOAddress" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <a-form-item label="Телефон">
          <a-input v-model:value="FUMOFormState.FUMOPhone" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <a-form-item label="E-mail">
          <a-input v-model:value="FUMOFormState.FUMOEmail" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>
        <a-form-item label="Сайт">
          <a-input v-model:value="FUMOFormState.FUMOSite" :disabled="!$auth.isLoggedIn()"/>
        </a-form-item>

        <a-form-item label="Состав ФУМО">
          <a-select
            v-model:value="FUMOFormState.FUMOStructureFUMO" :disabled="!$auth.isLoggedIn()"
            placeholder="please select your StructureFUMO"
          >
            <a-select-option
              v-for="file in Files"
              :key="file.id"
              v-bind:value="file.id"
            >
              {{ file.url }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Приказ о создании">
          <a-select
            v-model:value="FUMOFormState.FUMOCreateOrder" :disabled="!$auth.isLoggedIn()"
            placeholder="please select your CreateOrder"
          >
            <a-select-option
              v-for="file in Files"
              :key="file.id"
              v-bind:value="file.id"
            >
              {{ file.url }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "FUMO",
  data() {
    return {
      gridColumns: [
        { dataIndex: "id", title: "ID" },
        { dataIndex: "code", title: "Код" },
        { dataIndex: "title", title: "Наименование" },
        { dataIndex: "chairman", title: "Председатель" },
        { dataIndex: "address", title: "Адрес" },
        { dataIndex: "phone", title: "Телефон" },
        { dataIndex: "email", title: "Email" },
        { dataIndex: "site", title: "Сайт" },
        { dataIndex: "structureFUMO.url", title: "Структура ФУМО" },
        { dataIndex: "createOrder.url", title: "Приказ о создании" },
      ],
      gridData: [],
      visibleFUMO: false,
      thisFUMO: [],
      Files: [],
      FUMOFormState: {
        FUMOID: undefined,
        FUMOTitle: undefined,
        code: undefined,
        chairman: undefined,
        FUMOAddress: undefined,
        FUMOPhone: undefined,
        FUMOEmail: undefined,
        FUMOSite: undefined,
        FUMOStructureFUMO: undefined,
        FUMOCreateOrder: undefined,
      },
      selectedRowKeys: [],
      selectedRows: [],
    };
  },
  methods: {
    async reload() {
      this.gridData = (await axios.get("fumo")).data;
      this.Files = (await axios.get("file")).data;
    },
    rowClick(record, index) {
      return {
        onClick: () => {
          console.log(record, index);
          this.thisFUMO = record;
          this.FUMOFormState.FUMOID = record.id;
          this.FUMOFormState.FUMOTitle = record.title;
          this.FUMOFormState.code = record.code;
          this.FUMOFormState.chairman = record.chairman;
          this.FUMOFormState.FUMOAddress = record.address;
          this.FUMOFormState.FUMOPhone = record.phone;
          this.FUMOFormState.FUMOEmail = record.email;
          this.FUMOFormState.FUMOSite = record.site;
          this.FUMOFormState.FUMOStructureFUMO = record.structureFUMO.id;
          this.FUMOFormState.FUMOCreateOrder = record.createOrder.id;

          console.log(this.thisFUMO);
          this.showModalFUMO();
        },
      };
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    showModalFUMO() {
      this.visibleFUMO = true;
    },
    showModalNewFUMO() {
      this.FUMOFormState.FUMOID = undefined;
      this.FUMOFormState.FUMOTitle = undefined;
      this.FUMOFormState.code = undefined;
      this.FUMOFormState.chairman = undefined;
      this.FUMOFormState.FUMOAddress = undefined;
      this.FUMOFormState.FUMOPhone = undefined;
      this.FUMOFormState.FUMOEmail = undefined;
      this.FUMOFormState.FUMOSite = undefined;
      this.FUMOFormState.FUMOStructureFUMO = undefined;
      this.FUMOFormState.FUMOCreateOrder = undefined;

      this.visibleFUMO = true;
    },
    async handleOkFUMO() {
      console.log("FUMOModal.");
      this.visibleFUMO = false;
      if (this.FUMOFormState.FUMOTitle !== undefined) {
        if (this.FUMOFormState.FUMOID == undefined) {
          try {
            await axios.post("fumo", {
              code: this.FUMOFormState.code,
              title: this.FUMOFormState.FUMOTitle,
              chairman: this.FUMOFormState.chairman,
              address: this.FUMOFormState.FUMOAddress,
              phone: this.FUMOFormState.FUMOPhone,
              email: this.FUMOFormState.FUMOEmail,
              site: this.FUMOFormState.FUMOSite,
              StructureFUMOFileID: this.FUMOFormState.FUMOStructureFUMO,
              CreateOrderFileID: this.FUMOFormState.FUMOCreateOrder,
            });
          } finally {
            this.FUMOFormState.FUMOID = undefined;
            this.FUMOFormState.code = undefined;
            this.FUMOFormState.FUMOTitle = undefined;
            this.FUMOFormState.chairman = undefined;
            this.FUMOFormState.FUMOAddress = undefined;
            this.FUMOFormState.FUMOPhone = undefined;
            this.FUMOFormState.FUMOEmail = undefined;
            this.FUMOFormState.FUMOSite = undefined;
            this.FUMOFormState.FUMOStructureFUMO = undefined;
            this.FUMOFormState.FUMOCreateOrder = undefined;

            await this.reload();
          }
        } else {
          try {
            await axios.put("fumo", {
              id: this.FUMOFormState.FUMOID,
              code: this.FUMOFormState.code,
              title: this.FUMOFormState.FUMOTitle,
              chairman: this.FUMOFormState.chairman,
              address: this.FUMOFormState.FUMOAddress,
              phone: this.FUMOFormState.FUMOPhone,
              email: this.FUMOFormState.FUMOEmail,
              site: this.FUMOFormState.FUMOSite,
              StructureFUMOFileID: this.FUMOFormState.FUMOStructureFUMO,
              CreateOrderFileID: this.FUMOFormState.FUMOCreateOrder,
            });
          } finally {
            this.FUMOFormState.FUMOID = undefined;
            this.FUMOFormState.code = undefined;
            this.FUMOFormState.FUMOTitle = undefined;
            this.FUMOFormState.chairman = undefined;
            this.FUMOFormState.FUMOAddress = undefined;
            this.FUMOFormState.FUMOPhone = undefined;
            this.FUMOFormState.FUMOEmail = undefined;
            this.FUMOFormState.FUMOSite = undefined;
            this.FUMOFormState.FUMOStructureFUMO = undefined;
            this.FUMOFormState.FUMOCreateOrder = undefined;
            await this.reload();
          }
        }
      } else {
        return;
      }
    },
    async deleteEntity(selectedRowKeys) {
      console.log(selectedRowKeys);
      try {
        await axios.delete("fumo", {
          headers: { "Content-Type": "application/json; charset=utf-8" },
          data: selectedRowKeys,
        });
      } finally {
        selectedRowKeys = undefined;
        await this.reload();
      }
    },
    async editEntity(selectedRows) {
      console.log(selectedRows);
      this.FUMOFormState.FUMOID = selectedRows[0].id;
      this.FUMOFormState.code = selectedRows[0].code;
      this.FUMOFormState.FUMOTitle = selectedRows[0].title;
      this.FUMOFormState.chairman = selectedRows[0].chairman;
      this.FUMOFormState.FUMOAddress = selectedRows[0].address;
      this.FUMOFormState.FUMOPhone = selectedRows[0].phone;
      this.FUMOFormState.FUMOEmail = selectedRows[0].email;
      this.FUMOFormState.FUMOSite = selectedRows[0].site;
      this.FUMOFormState.FUMOStructureFUMO = selectedRows[0].structureFUMO;
      this.FUMOFormState.FUMOCreateOrder = selectedRows[0].createOrder;

      this.showModalFUMO();
    },
  },
  async created() {
    await this.reload();
  },
};
</script>